<template>
  <div
    :class="[
      'container-' + organism.containerWidth,
      'flex flex-wrap justify-center organism flexible-content-columns gap-x-8 gap-y-14',
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div
      v-for="(item, index) in organism.item"
      :key="'item-' + index"
      :id="item.id"
      :class="[item.cssClasses, 'w-72 max-w-full']"
    >
      <NuxtLink v-if="item.imageLink" :to="item.imageLink" class="image-link">
        <img
          v-if="item.fieldGroupName === 'item' && item.image?.sourceUrl"
          :src="getImageSrc(item.image, 'large')"
          alt=""
          :class="organism.rounded"
          loading="lazy"
        />
      </NuxtLink>
      <div v-else>
        <img
          v-if="item.fieldGroupName === 'item' && item.image?.sourceUrl"
          :src="getImageSrc(item.image, 'large')"
          alt=""
          :class="organism.rounded"
          loading="lazy"
        />
      </div>
      <img
        v-if="item.fieldGroupName === 'neighborhood_features'"
        :src="neighborhoodFeaturesIcon(item.category)"
        :alt="item.category"
        class=""
        loading="lazy"
      />
      <h3
        v-if="item.title1"
        class="text-4xl font-bold text-center title font-figtree"
      >
        {{ item.title1 }}
      </h3>
      <div
        v-if="item.fieldGroupName === 'neighborhood_features'"
        class="feature-title"
        v-html="item.title"
      ></div>
      <div
        v-if="item.description"
        class="description"
        v-html="item.description"
      ></div>
      <MoleculesButtons
        :buttons="item"
        :button-colors="[
          item.button1Color ?? 'button-blue',
          item.button2Color ?? 'button-white',
          item.button3Color ?? 'button-white',
        ]"
      />
    </div>

    <MoleculesButtons
      v-if="organism.button1"
      :buttons="organism"
      class="w-full"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});

const roundedness = computed(() => {
  return result.value?.organisms?.hlOrganisms?.hlOrganism ?? [];
});
</script>
